import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom"; // useNavigate for redirection
import {
  Button,
  CssBaseline,
  TextField,
  Typography,
  Paper,
  Box,
  Snackbar,
  Alert, // Import Snackbar and Alert from MUI
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import {
  BackgroundGrid,
  SignInForm,
  AvatarStyled,
  MainBox,
} from "./SignUpStyles";
import axios from "axios"; // Axios for HTTP request

function Copyright() {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      sx={{ mb: "0.5rem" }}
    >
      {"Copyright © "}
      <Link
        color="inherit"
        to="/"
        style={{ textDecoration: "none", color: "rgba(0, 0, 0, 0.6)" }}
      >
        Admission First
      </Link>{" "}
      {new Date().getFullYear()}.
    </Typography>
  );
}

// Axios function to handle registration request
const registerUser = async (formValues) => {
  let data = JSON.stringify({
    email: formValues.email,
    fname: formValues.firstName,
    lastname: formValues.lastName,
    mobile_number: formValues.mobileNumber,
    password: formValues.password,
    age: 25, // Modify this as per your needs
    education: "Bachelor's in Computer Science", // Modify accordingly
    city: "New York", // Modify accordingly
    state: "NY", // Modify accordingly
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: "https://consultancy-mvkp.onrender.com/register", // Adjust as per your backend
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  try {
    const response = await axios.request(config);
    return response.data;
  } catch (error) {
    console.error("Error during registration:", error);
    throw error;
  }
};

function SignUp() {
  const [formValues, setFormValues] = useState({
    email: "",
    mobileNumber: "",
    password: "",
    confirmPassword: "",
    firstName: "",
    lastName: "",
  });
  const [errors, setErrors] = useState({});
  const [emailExistsError, setEmailExistsError] = useState(""); // State for email exists error
  const [successAlert, setSuccessAlert] = useState(false); // State for success alert
  const [openDialog, setOpenDialog] = useState(false);
  const [btnSignUpDisabled, setBtnSignUpDisabled] = useState(true);

  const navigate = useNavigate(); // useNavigate hook for redirection

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const validateForm = () => {
    const { email, mobileNumber, password, confirmPassword } = formValues;
    const newErrors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const mobileRegex = /^[0-9]{10}$/;
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,16}$/;

    if (!emailRegex.test(email))
      newErrors.email = "Please enter a valid email address.";
    if (!mobileRegex.test(mobileNumber))
      newErrors.mobileNumber = "Please enter a valid 10-digit mobile number.";
    if (!passwordRegex.test(password))
      newErrors.password =
        "Password must include uppercase, lowercase, number, and special character.";
    if (password !== confirmPassword)
      newErrors.confirmPassword = "Passwords do not match.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const openDialogBox = () => {
    setOpenDialog(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setEmailExistsError(""); // Reset email exists error on submit

    if (validateForm()) {
      try {
        // Call the backend registerUser function
        const result = await registerUser(formValues);

        // Assuming success response from backend
        setSuccessAlert(true); // Show success alert

        // Hide success alert after 2 seconds and redirect to the login page
        setTimeout(() => {
          setSuccessAlert(false);
          navigate("/signin"); // Redirect to login page after alert
        }, 2000); // 2 seconds delay
      } catch (error) {
        // Handle any errors that occur during the registration
        setEmailExistsError("Registration failed, please try again.");
        console.error("Error during sign up:", error);
      }
    }
  };

  return (
    <MainBox component="main">
      <CssBaseline />
      <BackgroundGrid />
      <SignInForm component={Paper} elevation={6}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "20%",
          }}
        >
          <AvatarStyled>
            <LockOutlinedIcon />
          </AvatarStyled>
          <Typography component="h1" variant="h5">
            Sign Up
          </Typography>
        </Box>
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{ width: "100%", height: "70%" }}
        >
          <Box sx={{ height: "80%" }}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <TextField
                fullWidth
                label="First Name"
                name="firstName"
                autoComplete="given-name"
                required
                value={formValues.firstName}
                onChange={handleInputChange}
                sx={{ mb: 2, width: "48%" }}
              />
              <TextField
                fullWidth
                label="Last Name"
                name="lastName"
                autoComplete="family-name"
                required
                value={formValues.lastName}
                onChange={handleInputChange}
                sx={{ mb: 2, width: "48%" }}
              />
            </Box>
            <TextField
              fullWidth
              label="Mobile Number"
              name="mobileNumber"
              autoComplete="tel"
              required
              value={formValues.mobileNumber}
              onChange={handleInputChange}
              error={Boolean(errors.mobileNumber)}
              helperText={errors.mobileNumber}
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              label="Email Address"
              name="email"
              autoComplete="email"
              required
              value={formValues.email}
              onChange={handleInputChange}
              error={Boolean(errors.email) || Boolean(emailExistsError)}
              helperText={errors.email || emailExistsError}
              sx={{ mb: 2 }}
            />
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <TextField
                fullWidth
                label="Password"
                name="password"
                type="password"
                autoComplete="new-password"
                required
                value={formValues.password}
                onChange={handleInputChange}
                error={Boolean(errors.password)}
                helperText={errors.password}
                sx={{ mb: 2, width: "48%" }}
              />
              <TextField
                fullWidth
                label="Confirm Password"
                name="confirmPassword"
                type="password"
                required
                value={formValues.confirmPassword}
                onChange={handleInputChange}
                error={Boolean(errors.confirmPassword)}
                helperText={errors.confirmPassword}
                sx={{ mb: 2, width: "48%" }}
              />
            </Box>
          </Box>
          <Box sx={{ height: "20%" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Button
                type="submit"
                variant="contained"
                sx={{ mt: 3, mb: 2, width: "49%" }}
              >
                Sign Up
              </Button>
            </Box>
            <Box textAlign="right">
              <Link
                to="/signin"
                style={{ textDecoration: "none", color: "#1976d2" }}
              >
                Already have an account? Sign In Here!
              </Link>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "end",
            height: "10%",
          }}
        >
          <Copyright />
        </Box>
      </SignInForm>

      {/* Success Snackbar */}
      <Snackbar
        open={successAlert}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          Registration successful! Redirecting to login...
        </Alert>
      </Snackbar>
    </MainBox>
  );
}

export default SignUp;
