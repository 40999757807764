import React, { useState } from "react";
import {
  Typography,
  Button,
  TextField,
  Box,
  Card,
  CircularProgress,
  Alert,
  CardContent,
  Divider,
  CardActions,
} from "@mui/material";
import PlanCard from "../../component/subPlanCard_/PlanCard";

import VerifiedIcon from "@mui/icons-material/Verified";

import plans from "../../component/data";
import {
  PaymentContainer,
  PlanContainer,
  PlanCardWrapper,
  FormContainer,
  QRImage,
  StyledTextField,
  ResponseBox,
  ActivationMessage,
} from "./PaymentPageStyles";

import {
  StyledCard,
  StyledChip,
  StyledTitle,
  StyledHeading,
  StyledCourses,
  ContentTypography,
  PriceTypography,
  TextTypography,
  FeatureList,
  StyledButton,
} from "../../component/subPlanCard_/PlanCardStyles";

import axios from "axios"; // Import axios
import QR from "../../assets/QR-AdFirst.jpeg"; // Import your QR code image
import { useLocation } from "react-router-dom";

function PaymentPage() {
  const [showQR, setShowQR] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [transactionID, setTransactionID] = useState("");
  const [error, setError] = useState("");

  const location = useLocation();
  const { type, title, heading, courses, price, validity } = location.state || {};

  const [selectedplan, setSelectedplan] = useState("");

  const features = [
    "Notification/Events",
    "Require Documents",
    "Admission Process",
    "Reservation Quota",
    "Previous Year Cut Off",
    "Round Alerts",
  ]

  const handleChange = (e) => {
    setTransactionID(e.target.value);
    setError(""); // Reset error on input change
  };

  const handleShowQR = () => {
    setShowQR(true); // Show QR code
  };

  const validateTransactionID = () => {
    const trimmedValue = transactionID.trim();
    if (!trimmedValue) {
      setError("Transaction ID cannot be empty or spaces only.");
      return false;
    }

    // Check if it's a 12-digit number or 22 characters starting with 'T'
    const isValid12DigitNumber = /^\d{12}$/.test(trimmedValue);
    const isValidT22DigitNumber = /^T\d{22}$/.test(trimmedValue);

    if (!isValid12DigitNumber && !isValidT22DigitNumber) {
      setError("Enter a valid Transaction ID!");
      return false;
    }

    return true;
  };

  const handleSubmit = async () => {
    if (!validateTransactionID()) return;

    setIsDisabled(true);
    setIsSubmitting(true);

    const userdataString = sessionStorage.getItem("user");
    const userdata = JSON.parse(userdataString); // Parse the JSON string

    // Construct data to send in the POST request
    const postData = {
      id: userdata?.id, // The user ID, this should be dynamic, fetched from user session or profile.
      subscription: title, // This should be fetched based on the plan the user selects
      transaction_id: transactionID,
    };

    console.log(postData);
    

    try {
      const response = await axios.post(
        "https://consultancy-mvkp.onrender.com/update-transaction",
        postData, // Sending transaction data
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response.data); // Handle success
      setIsSubmitting(false);
      setSuccess(true);
    } catch (error) {
      console.error("Error updating transaction:", error);
      setError("Failed to submit transaction ID.");
      setIsSubmitting(false);
      setIsDisabled(false); // Enable the form again
    }
  };

  return (
    <PaymentContainer>
      <PlanContainer>
        <Typography variant="h5" gutterBottom>
          Your Selected Plan
        </Typography>

        <StyledCard variant="outlined">
          <StyledChip size="lg" variant="outlined">
            <Typography sx={{ fontSize: "1rem" }}>{type}</Typography>
          </StyledChip>
          <Box>
            <StyledTitle title={title} />
            <StyledHeading>{heading}</StyledHeading>
            <StyledCourses>{courses}</StyledCourses>
          </Box>

          <CardContent sx={{ mt: -1 }}>
            <Divider sx={{ border: "1px solid grey" }} />

            <ContentTypography>
              <PriceTypography variant="h4">₹{price}</PriceTypography>
              <TextTypography variant="body1">per month</TextTypography>
            </ContentTypography>

            <Divider sx={{ border: "1px solid grey" }} />

            <FeatureList>
              <ul>
                {features.map((feature, index) => (
                  <li key={index}>
                    <VerifiedIcon
                      sx={{ color: "green", fontSize: "large", marginRight: 3 }}
                    />
                    <Typography variant="body2">{feature}</Typography>
                  </li>
                ))}
              </ul>
            </FeatureList>
          </CardContent>
        </StyledCard>

      </PlanContainer>

      <FormContainer>
        {!showQR && (
          <Box sx={{ textAlign: "center" }}>
            <Typography variant="body1">Proceed with Payment</Typography>
            <Button
              variant="contained"
              onClick={handleShowQR} // Show the QR code
              sx={{ bgcolor: "#008080", "&:hover": { bgcolor: "#006666" } }}
            >
              Show QR Code for Payment
            </Button>
          </Box>
        )}

        {showQR && (
          <Card
            sx={{
              p: 3,
              width: "70%",
              height: "70%",
              boxShadow: 3,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography variant="h6" gutterBottom>
              Scan the QR Code
            </Typography>
            <QRImage component="img" src={QR} alt="QR Code" />
            <StyledTextField
              variant="outlined"
              label="Enter Transaction ID"
              fullWidth
              sx={{ mt: 2 }}
              value={transactionID}
              onChange={handleChange}
              error={!!error}
              helperText={error}
            />
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              onClick={handleSubmit} // Submit transaction ID
              sx={{
                mt: 3,
                bgcolor: "#008080",
                "&:hover": { bgcolor: "#006666" },
              }}
              disabled={isDisabled}
            >
              {isSubmitting ? <CircularProgress size={24} /> : "Submit Payment"}
            </Button>
          </Card>
        )}

        {success && (
          <ResponseBox>
            <Alert severity="success" sx={{ mt: 3 }}>
              Your Transaction ID has been submitted successfully!
            </Alert>
            <ActivationMessage>
              Your plan will be activated within 24 hours.
            </ActivationMessage>
          </ResponseBox>
        )}
      </FormContainer>
    </PaymentContainer>
  );
}

export default PaymentPage;
