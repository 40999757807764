import React, { useState } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import {
  Button,
  CssBaseline,
  TextField,
  Typography,
  Paper,
  Box,
  Snackbar,
  Alert,
} from "@mui/material";
import LockResetOutlinedIcon from "@mui/icons-material/LockResetOutlined";
import {
  BackgroundGrid,
//   ResetForm,
  AvatarStyled,
  FormBox,
  MainBox,
} from "../signIn_/SignInStyles"; // Assuming a similar styled component file for ResetPassword
import axios from "axios";

function Copyright() {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      sx={{ marginBottom: "1rem" }}
    >
      {"Copyright © "}
      <Link
        color="inherit"
        to="/"
        style={{ textDecoration: "none", color: "rgba(0, 0, 0, 0.6)" }}
      >
        Admission First
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

// function ResetPassword() {
//   const [email, setEmail] = useState("");
//   const [newPassword, setNewPassword] = useState("");
//   const [confirmPassword, setConfirmPassword] = useState("");
//   const [snackbarOpen, setSnackbarOpen] = useState(false);
//   const [error, setError] = useState(null);
//   const navigate = useNavigate();


  function ResetPassword() {
    const { token } = useParams(); // Retrieve the token from the URL
    const navigate = useNavigate();
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [error, setError] = useState(null);
  
    const handleSubmit = async (e) => {
      e.preventDefault();
  
      if (newPassword !== confirmPassword) {
        setError("Passwords do not match.");
        setSnackbarOpen(true);
        return;
      }
  
      try {
        const response = await axios.post(
          `https://consultancy-mvkp.onrender.com/reset-password/${token}`,
          { new_password: newPassword },
          { headers: { "Content-Type": "application/json" } }
        );
  
        if (response.status === 200) {
          setError(null);
          setSnackbarOpen(true);
          setTimeout(() => navigate("/signin"), 2000); // Redirect to login after success
        } else {
          setError("Invalid or expired token.");
        }
      } catch (err) {
        setError("An error occurred. Please try again.");
      }
      setSnackbarOpen(true);
    };
  
    // const handleCloseSnackbar = () => {
    //   setSnackbarOpen(false);
    // };
  

//   const handleCloseSnackbar = () => {
//     setSnackbarOpen(false);
//   };

  return (
    <MainBox component="main">
      <CssBaseline />
      <BackgroundGrid item xs={false} sm={4} md={7} />
      <Box component={Paper} elevation={6}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <AvatarStyled>
            <LockResetOutlinedIcon />
          </AvatarStyled>
          <Typography component="h1" variant="h5">
            Reset Password
          </Typography>
        </Box>
        <FormBox component="form" noValidate onSubmit={handleSubmit} sx={{ px: 2 }} >
          <TextField
            margin="normal"
            required
            fullWidth
            name="newPassword"
            label="New Password"
            type="password"
            id="newPassword"
            autoComplete="new-password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="confirmPassword"
            label="Confirm New Password"
            type="password"
            id="confirmPassword"
            autoComplete="new-password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Reset Password
          </Button>
        </FormBox>
        <Copyright />
      </Box>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        // onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
        //   onClose={handleCloseSnackbar}
          severity={error ? "error" : "success"}
          sx={{ width: "100%" }}
        >
          {error || "Password reset successful!"}
        </Alert>
      </Snackbar>
    </MainBox>
  );
}

export default ResetPassword;
