import React, { useState } from "react";
import {
  TextField,
  Paper,
  Box,
  Autocomplete,
  Button,
  Typography,
  styled,
} from "@mui/material";
import axios from "axios";
// import plans from '../../data'

const StyledTextField = styled(TextField)(({ theme, disabled }) => ({
  "& .MuiInputLabel-root": {
    color: "#006666", // Custom color for label
  },

  "& .MuiInputLabel-root.Mui-focused": {
    color: "#006666", // Custom color when label is focused and shrunk
  },

  "& .MuiOutlinedInput-root": {
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#008080 !important", // Custom color for focused outline
      borderWidth: "1px",
    },
  },

  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#008080 !important", // Custom color for notched outline
    borderWidth: "1px",
  },
}));

const SetUpdates = ({ plans }) => {
  const [msg, setMsg] = useState("");
  const [selectedSubPlan, setSelectedSubPlan] = useState();

  const handleInputChange = (event) => {
    setMsg(event.target.value); // Update the state with the input value
  };

  const handlePlanChange = (event, newValue) => {
    setSelectedSubPlan(newValue); // Update state with the selected plan
    console.log("Selected Plan:", newValue); // Log the selected plan to see it
  };

  const handleSubmitNotifications = () => {
    const data = {
      message: msg,
      subscription: selectedSubPlan, // replace with the actual subscription name
    };

    console.log(data);

    axios
      .post("https://consultancy-mvkp.onrender.com/admin/send_notification", data)
      .then((response) => {
        console.log(response.data); // Handle success response
      })
      .catch((error) => {
        console.error(
          "Error:",
          error.response ? error.response.data : error.message
        ); // Handle error
      });
  };

  const title = plans.map((plan) => plan.title);

  return (
    <Box sx={{ width: "70vw", height: "auto", m: 1 }}>
      <Paper
        elevation={8}
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            m: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            gap: 2,
          }}
        >
          <Box sx={{ width: "100%", display: "flex", justifyContent: "left" }}>
            <Typography variant="h6" sx={{ color: "#006666" }}>
              Set Notifications :
            </Typography>
          </Box>
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <Autocomplete
              disablePortal
              options={title}
              value={selectedSubPlan}
              onChange={handlePlanChange}
              sx={{ width: "90%" }}
              renderInput={(params) => (
                <StyledTextField {...params} label="Select Plan" />
              )}
            />
          </Box>
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <StyledTextField
              id="outlined-multiline-static"
              label="Set Notifications"
              multiline
              rows={4}
              placeholder="Write Your Notifications Here"
              value={msg}
              onChange={handleInputChange}
              sx={{ width: "90%" }}
            />
          </Box>
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <Button
              onClick={handleSubmitNotifications}
              variant="contained"
              sx={{
                width: "20vw",
                bgcolor: "#008080",
                "&:hover": { bgcolor: "#006666" },
              }}
            >
              Send
            </Button>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default SetUpdates;
