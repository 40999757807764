import React, { useEffect, useState } from "react";
import { Autocomplete, Box, Button, Dialog, DialogContent, DialogTitle, Divider, IconButton, styled, TextField } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { fetchPlansData } from "../../../data_/SubPlanData";
// import CardData from '../../../subPlanCard_/CardData'

const StyledTextField = styled(TextField)(({ theme, disabled }) => ({
  // Add your styles here
}));

function UpdateSubPlan({ openPlan, setOpenPlan }) {
  const [plans, setPlans] = useState([]); // State to hold the fetched plans
  const [selectedPlan, setSelectedPlan] = useState(""); // State to hold the selected plan
  const [newPlanDetails, setNewPlanDetails] = useState({ title: "", heading: "", validity: "", price: "", course_offered: "" });

  // Fetch the plans data when the component mounts
  const getPlans = async () => {
    try {
      const fetchedPlans = await fetchPlansData(); // Fetch data from API
      // console.log('Fetched Plans:', fetchedPlans); // Log the fetched plans to ensure they are correct
      setPlans(fetchedPlans); // Set the fetched plans to the state
    } catch (error) {
      console.error('Error fetching plans:', error);
    }
  };

  useEffect(() => {
    getPlans(); // Call the function to fetch plans
  }, []); // Empty dependency array to ensure it only runs once on mount

  const handleNewPlanChange = (e) => {
    setNewPlanDetails({ ...newPlanDetails, [e.target.name]: e.target.value });
  };

  const handleAddNewPlan = async () => {
    try {
      const response = await fetch('https://consultancy-mvkp.onrender.com/add-subscription', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...newPlanDetails,
          type: 'basic' // or whatever type you want to set
        }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log(data.message);
        // Optionally refresh the plans list
        await getPlans(); // Use getPlans here
        setNewPlanDetails({ title: "", heading: "", validity: "", price: "", course_offered: "" }); // Reset the form
      } else {
        const errorData = await response.json();
        console.error('Error adding subscription:', errorData.message);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleUpdatePlan = async () => {
    try {
      const response = await fetch(`https://consultancy-mvkp.onrender.com/update-subscription/${selectedPlan}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...newPlanDetails,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log(data.message);
        // Optionally refresh the plans list
        await getPlans(); // Use getPlans here
        setNewPlanDetails({ title: "", heading: "", validity: "", price: "", course_offered: "" }); // Reset the form
      } else {
        const errorData = await response.json();
        console.error('Error updating subscription:', errorData.message);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleDeletePlan = async () => {
    try {
      const response = await fetch(`https://consultancy-mvkp.onrender.com/delete-subscription/${selectedPlan}`, {
        method: 'DELETE',
      });

      if (response.ok) {
        const data = await response.json();
        console.log(data.message);
        // Optionally refresh the plans list
        await getPlans(); // Use getPlans here
        setSelectedPlan(""); // Clear selected plan
      } else {
        const errorData = await response.json();
        console.error('Error deleting subscription:', errorData.message);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleClose = () => {
    setOpenPlan(false);
    setNewPlanDetails({ title: "", heading: "", validity: "", price: "", course_offered: "" }); // Reset form when closing
    setSelectedPlan("");
  };

  return (
    <Dialog
      onClose={handleClose}
      open={openPlan}
      maxWidth='md'
      PaperProps={{
        sx: {
            width: '70vw',
            height: "90vh",
        },
      }}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>Update Subscription Plans</DialogTitle>
      <IconButton onClick={handleClose} sx={{ position: "absolute", right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}>
        <CloseIcon/>
      </IconButton>
      <Divider/>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }} >
        <Autocomplete
            disablePortal
            options={plans.map((plan) => plan.title)}
            onChange={(event, newValue) => setSelectedPlan(newValue)} // Update selected plan
            renderInput={(params) => <StyledTextField {...params} label="Select Existing Plan" />}
        />
        <Box sx={{ display: 'flex', flexDirection: 'column', gap : 2 }} >
            <Box sx={{ display: 'flex', gap: 2, justifyContent: 'space-between' }} >
                <StyledTextField 
                  required
                  name="type"
                  variant="outlined"
                  label="Plan Type"
                  value={newPlanDetails.type} // Bind the value to the state
                  onChange={handleNewPlanChange} // Update state on change
                />
                <StyledTextField 
                  required
                  name="price"
                  variant="outlined"
                  label="Price"
                  value={newPlanDetails.price} // Bind the value to the state
                  onChange={handleNewPlanChange} // Update state on change
                />
            </Box>
            <StyledTextField 
              required
              name="title"
              variant="outlined"
              label="Title"
              value={newPlanDetails.title} // Bind the value to the state
              onChange={handleNewPlanChange} // Update state on change
            />
            <StyledTextField 
              required
              name="heading"
              variant="outlined"
              label="Heading"
              value={newPlanDetails.heading} // Bind the value to the state
              onChange={handleNewPlanChange} // Update state on change
            />
            <StyledTextField 
              required
              name="course_offered"
              variant="outlined"
              label="Courses Offered"
              value={newPlanDetails.course_offered} // Bind the value to the state
              onChange={handleNewPlanChange} // Update state on change
            />
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }} >
            <Button onClick={handleAddNewPlan} variant="contained" sx={{ width: '10vw', bgcolor: '#008080', '&:hover': { bgcolor: '#006666' } }} >Add New Plan</Button>
            <Button onClick={handleUpdatePlan} variant="contained" sx={{ width: '10vw', bgcolor: '#008080', '&:hover': { bgcolor: '#006666' } }} >Update Plan</Button>
            <Button onClick={handleDeletePlan} variant="contained" sx={{ width: '10vw', bgcolor: '#008080', '&:hover': { bgcolor: '#006666' } }} >Delete</Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default UpdateSubPlan;
