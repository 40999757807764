import React, { useEffect, useState } from "react";
import UserInfo from "../../component/profileComps_/userDetails_/UserInfo";
import { Box } from "@mui/material";
import SubDetails from "../../component/profileComps_/subDetails_/SubDetails";
import Notification from "../../component/profileComps_/notification_/Notifications";
import UserContent from "../../component/profileComps_/userContent_/UserContent";
import EditProfile from "../../component/profileComps_/editProfile_/EditProfile";
import Buttons from "../../component/profileComps_/buttons_/Buttons";
import axios from "axios";
import UserDataDummy from "./UserDataDummy";

function UserProfileDesktop() {
  const [userData, setUserData] = useState(null); // State for user data
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        // Retrieve user_id from sessionStorage and parse it if necessary
        const userdataString = sessionStorage.getItem("user");
        const userdata = JSON.parse(userdataString); // Parse the JSON string

        // Access user ID
        const userId = userdata?.id; // Use optional chaining to avoid errors if userdata is null

        if (userId) {
          const response = await axios.get(
            `https://consultancy-mvkp.onrender.com/user_profile/${userId}`, // Use template literals
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          setUserData(response.data); // Set the fetched user data

          console.log("Updated userData:", userData);
        } else {
          console.error("User ID not found in session storage");
        }
      } catch (error) {
        console.error("Error fetching user profile:", error);
      }
    };

    fetchUserProfile(); // Call the fetch function
  }, []);

  return (
    <Box sx={{ display: "flex", justifyContent: "center", mt: "5rem" }}>
      <Box>
        {userData && (
          <UserInfo userData={userData} handleClickOpen={handleClickOpen} />
        )}
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        {userData && <SubDetails userData={userData} />}
        <Buttons />
        <Notification />
        <UserContent />
        <EditProfile handleClose={handleClose} open={open} />
      </Box>
    </Box>
  );
}

export default UserProfileDesktop;
