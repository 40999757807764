import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import {
  Button,
  CssBaseline,
  TextField,
  FormControlLabel,
  Checkbox,
  Grid,
  Typography,
  Paper,
  Box,
  Snackbar,
  Alert,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import {
  BackgroundGrid,
  SignInForm,
  AvatarStyled,
  FormBox,
  MainBox,
} from "./SignInStyles";
import axios from "axios";
import UserDataDummy from "../userProfile_/UserDataDummy";

function Copyright() {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      sx={{ marginBottom: "1rem" }}
    >
      {"Copyright © "}
      <Link
        color="inherit"
        to="/"
        style={{ textDecoration: "none", color: "rgba(0, 0, 0, 0.6)" }}
      >
        Admission First
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

function SignIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [error, setError] = useState(null);
  const [user, setUser] = useState(null); // Manage user state here directly
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        "https://consultancy-mvkp.onrender.com/login",
        { email, password },
        { headers: { "Content-Type": "application/json" } }
      );

      if (response.status === 200) {
        const userData = response.data;
        sessionStorage.setItem("user", JSON.stringify(userData)); // Store user data in session storage
        // Optionally set the user in a context or state if needed
        // setUser(userData);
        setError(null);
        setSnackbarOpen(true);
        navigate("/"); // Redirect after successful login
      }
    } catch (error) {
      setError("Invalid email or password");
      setSnackbarOpen(true);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <MainBox component="main">
      <CssBaseline />
      <BackgroundGrid item xs={false} sm={4} md={7} />
      <SignInForm component={Paper} elevation={6}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <AvatarStyled>
            <LockOutlinedIcon />
          </AvatarStyled>
          <Typography component="h1" variant="h5">
            Sign In
          </Typography>
        </Box>
        <FormBox component="form" noValidate onSubmit={handleSubmit}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

          {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          /> */}

          <Link
            to="/forgot_password"
            variant="body2"
            style={{ textDecoration: "none", color: "#1976d2" }}
          >
            Forgot password?
          </Link>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In
          </Button>
          <Box sx={{ textAlign: "center" }}>
            <Link
              to="/signup"
              variant="body2"
              style={{ textDecoration: "none", color: "#1976d2" }}
            >
              Don't have an account? Sign Up Here!
            </Link>
          </Box>
        </FormBox>
        <Copyright />
      </SignInForm>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={error ? "error" : "success"}
          sx={{ width: "100%" }}
        >
          {error || "Login successful!"}
        </Alert>
      </Snackbar>
    </MainBox>
  );
}

export default SignIn;
