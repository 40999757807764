// NotificationsMobile.js

import React, { useState, useEffect } from "react";
import { Box, Paper, Typography, Divider } from "@mui/material";
import axios from "axios";

function NotificationsMobile() {
  const [notificationsMobile, setNotificationsMobile] = useState([]);

  useEffect(() => {
    const userdataString = sessionStorage.getItem("user");
    const userdata = JSON.parse(userdataString);
    const userId = userdata?.id;

    const fetchNotificationsMobile = async () => {
      try {
        const response = await axios.get(`https://consultancy-mvkp.onrender.com/user/notificationsMobile/${userId}`);
        setNotificationsMobile(response.data);
      } catch (error) {
        console.error("Error fetching notificationsMobile:", error);
      }
    };

    fetchNotificationsMobile();
  }, []);

  return (
    <Paper sx={{ marginTop: 2, padding: 2, borderRadius: 3 }}>
      <Typography variant="h6" sx={{ color: "#008080", marginBottom: 2 }}>
        NotificationsMobile
      </Typography>
      <Divider />
      <Box sx={{ marginTop: 2 }}>
        {notificationsMobile.map((notification, index) => (
          <Box key={index} sx={{ marginBottom: 2 }}>
            <Typography variant="body1" sx={{ color: notification.is_read ? "gray" : "black" }}>
              {notification.message}
            </Typography>
            <Typography variant="body2" sx={{ color: "gray" }}>
              {notification.subscription}
            </Typography>
          </Box>
        ))}
      </Box>
    </Paper>
  );
}

export default NotificationsMobile;
