import { Box, Button } from "@mui/material";
import axios from "axios";

// Function to download a document based on its type
const downloadDocument = async (pdfType) => {
  try {
    const userdataString = sessionStorage.getItem("user");
    const userdata = JSON.parse(userdataString); // Parse the JSON string

    // Access user ID
    const userId = userdata?.id; // Use optional chaining to avoid errors if userdata is null

    const response = await axios.get(
      `https://consultancy-mvkp.onrender.com/get_documents/${pdfType}/${userId}`,
      {
        responseType: "blob", // Handle the response as a file
      }
    );
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `document_${pdfType}.pdf`);
    document.body.appendChild(link);
    link.click();
  } catch (error) {
    console.error("Error downloading document:", error);
  }
};

// List of document types and their labels
const documentsList = [
  { label: "Announcements", type: "announcements" },
  { label: "Admission Process", type: "admission_process" },
  { label: "Required Documents", type: "required_documents" },
  { label: "Eligibility Criteria", type: "eligibility_criteria" },
  { label: "Seat Matrix", type: "seat_matrix" },
  { label: "Merit List", type: "merit_list" },
  { label: "Previous Year Cut-Off", type: "previous_year_cutoff" },
  { label: "Round Alerts", type: "round_alerts" },
  { label: "Institutes", type: "institutes" },
  { label: "Fee Structure", type: "fee_structure" },
];

// Buttons component
function Buttons() {
  return (
    <Box
          sx={{
            width: "70vw",
            height: 'auto',
            m: 1,
            display: "grid",
            gridTemplateColumns: "auto auto auto auto auto",
            gap: 3,
          }}
        >
      {documentsList.map((doc, index) => (
        <Button
          key={index}
          onClick={() => downloadDocument(doc.type)}
          variant="contained"
          sx={{
            bgcolor: "#008080",
            margin: 1,
            '&:hover': {
              bgcolor: '#006666'
            }
          }} // Adjust margin as needed
        >
          {doc.label}
        </Button>
      ))}
    </Box>
  );
}

export default Buttons;
